import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';
import { getLawFirmFromToken, getRoleFromToken } from "../authService";

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
    const headers = new Headers(options.headers || {});
    headers.append('Accept', 'application/json');

    const lawFirm = getLawFirmFromToken();
    if (url.includes('/transfer-notes') && lawFirm) {
        headers.append('X-Law-Firm', lawFirm);
    }

    const role = getRoleFromToken();
    if (role) {
        headers.append('X-Role', role);
    }

    options.headers = headers;

    return fetchUtils.fetchJson(url, options);
};

const customDataProvider = {
    ...jsonServerProvider('/api', httpClient),

    getList: async (resource:any, params: any) => {
        const { filter } = params;

        // Perform the default GET_LIST request
        const response = await jsonServerProvider('/api', httpClient).getList(resource, params);

        // Check if the filter contains an ID and fetch the selected value if needed
        if (filter.id) {
            const selectedResponse = await jsonServerProvider('/api', httpClient).getOne(resource, { id: filter.id });

            // Append the selected value if it's not already in the results
            if (!response.data.some((item) => item.id === filter.id)) {
                response.data.unshift(selectedResponse.data);
            }
        }

        return response;
    }
};

export default customDataProvider;