import { FC } from "react";
import {
    BooleanField,
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    List, SearchInput,
    ShowButton,
    TextField,
    TopToolbar
} from "react-admin";

interface Props {}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const postFilters = [
    <SearchInput source="name" alwaysOn />,
];


const CampaignsList: FC<Props> = (props) => {

    return (
        <List {...props} pagination={false} actions={<ListActions/>} filters={postFilters}>
            <Datagrid bulkActionButtons={false}>
                <TextField source={'ats_campaign_id'} label={'Campaign Id'} />
                <TextField source={'name'} label={'Campaign Name'} />
                <TextField source={'law_firm.name'} label={'Law Firm'} />
                <TextField source={'tort.name'} label={'Tort'} />
                <TextField source={'retainers'} />
                <DateField source={'start_date'} label={'Started'}/>
                <DateField source={'end_date'} label={'Ended'} />
                <BooleanField source={'is_active'} label={'Active'}/>
                <ShowButton label={'View'}/>
                <EditButton/>
            </Datagrid>
        </List>
    )
}

export default CampaignsList
