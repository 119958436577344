import { FC } from "react";
import { BooleanField, CreateButton, Datagrid, EditButton, List, SearchInput, TextField, TopToolbar } from "react-admin";

interface Props {}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const postFilters = [
    <SearchInput source="name" alwaysOn />,
];


const UsersList: FC<Props> = (props) => {

    return (
        <List {...props} pagination={false} actions={<ListActions/>} filters={postFilters}>
            <Datagrid bulkActionButtons={false}>
                <TextField source={'id'} />
                <TextField source={'email'} />
                <TextField source={'first_name'} />
                <TextField source={'last_name'} />
                <BooleanField source={'is_admin'} />
                <BooleanField source={'is_super_admin'} />
                <BooleanField source={'is_law_firm'} />
                <TextField source={'law_firm.name'} />
                <EditButton/>
            </Datagrid>
        </List>
    )
}

export default UsersList