import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import IntakeDetailsView from "./IntakeDetailsView";
import { IIntake } from "../../../services/campaignService";

interface Props {
    intake: IIntake
    onClose: () => void
}


const IntakeDetailDialog: React.FC<Props> = (props) => {
    const { intake, onClose } = props
   // const [open, setOpen] = useState(false);

  // const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
  //   event.stopPropagation(); // Stop event propagation
  //   setOpen(false);
  // };

    return (
        <Dialog open={true} maxWidth={'xs'} fullWidth={true}>
          <DialogTitle>Intake Details Status</DialogTitle>
          <DialogContent>
              <IntakeDetailsView intake={intake} />
          </DialogContent>
          <DialogActions>
            <Button variant={'contained'} onClick={() => onClose?.()}>Close</Button>
            {/*<Button onClick={handleReturn} color="primary">Save</Button>*/}
          </DialogActions>
        </Dialog>
    )
}

export default IntakeDetailDialog