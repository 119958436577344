import {
    AutocompleteInput,
    BooleanInput,
    Edit,
    EditProps,
    ListButton,
    ReferenceInput,
    SimpleForm,
    TextInput,
    TopToolbar
} from "react-admin";
import { Box, Grid } from "@mui/material";
const EditActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const UsersEdit: React.FC<EditProps> = (props) => {

    return (
        <Edit actions={<EditActions/>}>
            <SimpleForm>
                <Box style={{ maxWidth: '800px', margin: 'auto' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'email'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'first_name'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source={'last_name'} />
                        </Grid>

                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <TextInput fullWidth source={'password'} />*/}
                        {/*</Grid>*/}

                        <Grid item xs={12} md={6}>
                            <BooleanInput fullWidth source={'is_admin'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BooleanInput fullWidth source={'is_super_admin'} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <BooleanInput fullWidth source={'is_law_firm'} />
                        </Grid>

                        <Grid item xs={12}>
                            <ReferenceInput source="law_firm_id" reference="law-firms">
                                <AutocompleteInput
                                    optionText="name"
                                    filterToQuery={(searchText) => ({ law_firm_name: searchText })}
                                />
                            </ReferenceInput>
                        </Grid>

                    </Grid>
                </Box>
            </SimpleForm>
        </Edit>
    )
}