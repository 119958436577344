import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Link, Tooltip } from "@mui/material";

interface Props {
    lawFirmCampaignCountRecords: ILawFirmCampaignCountRecord []
}

const QuickSummary: React.FC<Props> = (props) => {
    const { lawFirmCampaignCountRecords } = props

    if (lawFirmCampaignCountRecords.length === 0) {
        return <></>
    }

    return (
        <Box mb={2}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Law Firm</TableCell>
                            <TableCell>Tort</TableCell>
                            <TableCell>Campaign Name</TableCell>
                            <TableCell align="right">Retainers</TableCell>
                            <TableCell align="right">QC</TableCell>
                            <TableCell align="right">
                                <Tooltip
                                    placement="top"
                                    title="Sent to Law Firm = Submitted to Law Firm + DQ by Firm + Unresponsive + Unresponsive Transferred to Firm + Replacement Sent"
                                    arrow
                                >
                                    <Box component={'span'} sx={{ cursor: 'pointer' }}>Sent to Law Firm</Box>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="right">
                                <Tooltip
                                    placement="top"
                                    title="Accepted by Law Firm = Sent to Law Firm - (DQ by Firm + Unresponsive)"
                                    arrow
                                >
                                    <Box component="span" sx={{ cursor: 'pointer' }}>
                                        Accepted by Law Firm
                                    </Box>
                                </Tooltip>
                            </TableCell>
                            <TableCell align="right">Remaining Needed</TableCell>
                        </TableRow>
                    </TableHead>
                    {lawFirmCampaignCountRecords.map(({ law_firm, campaign_count_records }) => (
                        <TableBody>
                            {campaign_count_records.map((row) => (
                                <TableRow key={row.campaign_id}>
                                    <TableCell component="th" scope="row">
                                        <Link href={`/law-firms/${row.law_firm_id}/show`}>{law_firm}</Link>
                                    </TableCell>
                                    <TableCell>{row.tort_name}</TableCell>
                                    {/*<TableCell>{row.ats_campaign_id}</TableCell>*/}
                                    <TableCell>
                                        <Link href={`/campaigns/${row.campaign_id}/show`}>{row.campaign_name}</Link>
                                    </TableCell>
                                    <TableCell align="right">{row.retainers}</TableCell>
                                    <TableCell align="right">{row.dropped_to_qc}</TableCell>
                                    <TableCell align="right">{row.sent_to_law_firm}</TableCell>
                                    <TableCell align="right">{row.accepted_by_law_firm}</TableCell>
                                    <TableCell align="right">{row.remaining_needed}</TableCell>
                                </TableRow>
                            ))}

                        </TableBody>

                    ))}
                </Table>
            </TableContainer>
        </Box>
    )
}

export default QuickSummary