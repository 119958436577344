import React from 'react'
import { IIntake } from "../../../services/campaignService";
import { Box, Typography } from "@mui/material";

interface Props {
    intake: IIntake
    onGoBack?: () => void
}

const IntakeDetailsView: React.FC<Props> = (props) => {
  const { intake } = props

  const getIntakeDetailRow = (label: string, value: string | undefined | null) => {
    return (
        <Box>
          <Typography component={'label'} sx={{ fontWeight: 'bold' }}>{label}: </Typography>
          <Typography component={'span'}>{value}</Typography>
        </Box>
    )
  }

  if (!intake) {
    return <></>
  }

  return (
      <Box>
        <Box mb={2}>
          {/*<Typography variant={'h1'} mb={2}>Intake Details</Typography>*/}
          {getIntakeDetailRow('Injured Party Name', intake.injured_party_name)}
          {getIntakeDetailRow('QC Status', intake.qc_status)}
          {getIntakeDetailRow('Phone Number', intake.phone_number)}
          {getIntakeDetailRow('Email', intake.email)}
          {getIntakeDetailRow('DOB', intake.dob)}
          {getIntakeDetailRow('Address', intake.address)}
        </Box>
        {/*<Box>*/}
        {/*  <Button variant={'contained'} color={'primary'} onClick={() => onGoBack?.()}>Go Back</Button>*/}
        {/*</Box>*/}
      </Box>
  )
}

export default IntakeDetailsView