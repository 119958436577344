import React, { useState } from 'react';
import { Button, TextField, Container, Box, Typography } from '@mui/material';
import { useNotify, useRedirect } from 'react-admin';
import { resetPassword } from "../services/authService";
import { useLocation } from "react-router-dom";

const ResetPassword: React.FC = () => {
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const token = query.get('token')

    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const notify = useNotify();
    const redirect = useRedirect();

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            notify('Passwords do not match', { type: 'warning' });
            return;
        }

        if (!token) {
            notify('Token mismatch', { type: 'warning' });
            return;
        }

        const result = await resetPassword(token, newPassword);
        if(result){
            notify('Password changed successfully', { type: 'success' });
            redirect('/');
        }else{
            notify('Failed to change password', { type: 'error' });
        }
    };

    if (!token) {
        return <Box>Invalid Token</Box>
    }

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Change Password
                </Typography>
                <Box sx={{ mt: 3 }}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword"
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Change Password
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPassword;
