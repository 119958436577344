

export interface Title {
    // name: string;
    // type: string;
    // xuid: string;
    // titleId: string;
    // hours_played: number;
    // minutes_played: number;
    // last_time_played: string;

    title: string;
    timePlayed: string;
    lastPlayed: string;
    console: string;
}

export interface GamerTagResponse {
    gamer_tag: string;
    titles: Title[];
    // total_hours_played: number;
    // total_minutes_played: number;
}

export const fetchGamerTagData = async (gamerTag: string, gameConsole: string): Promise<GamerTagResponse | null> => {
    try {
        const response = await fetch(`/api/gamer-tags?gamerTag=${encodeURIComponent(gamerTag)}&console=${gameConsole}`);
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    return null;
};
