import React, { useEffect, useState } from 'react';
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    Typography,
    TextField,
    FormControlLabel, Radio,
    FormControl,
    RadioGroup, Box, FormLabel
} from '@mui/material';
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { fetchGamerTagData, GamerTagResponse } from "../../services/gamerTagService";
import Button from "@mui/material/Button";

const GamerTagLookup: React.FC = () => {
    const [data, setData] = useState<GamerTagResponse | null>(null);
    const [gamerTag, setGamerTag] = useState<string>("Bogey Vibes");
    const [platform, setPlatform] = useState('xbox'); // default to xbox
    const [loading, setLoading] = useState<boolean>(false);

    const loadData = async () => {
        setData(null)
        setLoading(true);
        const result = await fetchGamerTagData(gamerTag, platform);
        setData(result);
        setLoading(false);
    };

    // Load initial data on mount
    useEffect(() => {
        // loadData();
    }, []);
    console.log(data)

    // if(!data){
    //     return null;
    // }

    return (
        <Paper sx={{ padding: 2, margin: "20px auto", maxWidth: 800 }}>

            <Typography variant="h6" gutterBottom>
                Gamer Tag Lookup
            </Typography>

            <div>
                <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    flexWrap="wrap"
                    sx={{ padding: 2 }}
                >
                    <TextField
                        label="Enter Gamer Tag"
                        value={gamerTag}
                        onChange={(e) => setGamerTag(e.target.value)}
                    />
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Platform</FormLabel>
                        <RadioGroup
                            row
                            value={platform}
                            onChange={(e) => setPlatform(e.target.value)}
                        >
                            <FormControlLabel
                                value="xbox"
                                control={<Radio />}
                                label="Xbox"
                            />
                            <FormControlLabel
                                value="psn"
                                control={<Radio />}
                                label="PlayStation"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Button
                        variant="contained"
                        onClick={loadData}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Search"}
                    </Button>
                </Box>
            </div>

            {/*<Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>*/}
            {/*    Gamer Tag: {data?.gamer_tag || "N/A"}*/}
            {/*</Typography>*/}

            {data?.titles && (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Console</strong></TableCell>
                                <TableCell><strong>Game</strong></TableCell>
                                <TableCell align="right"><strong>Hours Played</strong></TableCell>
                                {/*<TableCell align="right"><strong>Minutes Played</strong></TableCell>*/}
                                <TableCell align="right"><strong>Last Played</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.titles?.map((title) => (
                                <TableRow key={title.title}>
                                <TableCell>{title.console}</TableCell>
                                <TableCell>{title.title}</TableCell>
                                <TableCell align="right">{title.timePlayed}</TableCell>
                                <TableCell align="right">{title.lastPlayed}</TableCell>
                                    {/*<TableCell align="right">*/}
                                    {/*    {new Date(title.last_time_played).toLocaleDateString()}*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Paper>
    )
};

export default GamerTagLookup;
