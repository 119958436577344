import React, { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuthenticated } from "react-admin";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { downloadCampaignIntakes, IIntake } from "../../../../services/campaignService";
import Button from "@mui/material/Button";
import moment from "moment/moment";
import AdminActionMenu from "../../../../components/common/AdminDashboard/AdminActionMenu";
import IntakeDetailDialog from "../../../../components/common/intakes/IntakeDetailDialog";

interface Props {}

interface ILawFirmOption {
    id: number
    name: string
}

const QCIntakesByCampaign:React.FC<Props> = (props) => {
    useAuthenticated()

    const [lawFirms, setLawFirms] = useState<ILawFirmOption[]>([]);
    const [selectedLawFirm, setSelectedLawFirm] = useState<number | string>('');

    const [campaigns, setCampaigns] = useState<ICampaignOption[]>([]);
    const [selectedCampaign, setSelectedCampaign] = useState<number | string>('');

    const [currentCampaign, setCurrentCampaign] = useState<IDashboardCampaign | undefined>(undefined);

    const [gridData, setGridData] = useState<IIntake[]>([]);
    const [, setCampaignCounts] = useState<any[]>([]);

    const [viewMoreDlgOpen, setViewMoreDlgOpen] = useState<boolean>(false)
    const [viewMoreIntake, setViewMoreIntake] = useState<IIntake | undefined>(undefined)

    const handleLawFirmChange = (event: any) => {
        setSelectedLawFirm(event.target.value);
        setSelectedCampaign('')
    };

    const handleCampaignChange = (event: any) => {
        setSelectedCampaign(event.target.value);
    };

    const handleViewMoreClicked = (intake: IIntake) => {
        setViewMoreIntake(intake)
        setViewMoreDlgOpen(true)
    }

    const handleViewMoreDlgClosed = () => {
        setViewMoreIntake(undefined)
        setViewMoreDlgOpen(false)
    }

    useEffect(() => {
        const fetchLawFirms = async () => {
            try {
                const response = await fetch('/api/law-firms');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); // Correct method to parse the response body as JSON
                setLawFirms(data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchLawFirms();

    }, []);

    useEffect(() => {
        const fetchLawFirmCampaigns = async () => {
            try {
                const response = await fetch(`/api/law-firms/${selectedLawFirm}/campaigns`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json(); // Correct method to parse the response body as JSON
                setCampaigns(data);
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };
        fetchLawFirmCampaigns();
    }, [selectedLawFirm]);

    useEffect(() => {
        const fetchCampaign = async () => {
            const response = await fetch(`/api/campaigns/${selectedCampaign}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCurrentCampaign(data)
        }

        const fetchCampaignIntakes = async () => {
            const response = await fetch(`/api/campaigns/${selectedCampaign}/intakes`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data) {
                setGridData(data.intakes)
                setCampaignCounts(data.counts)
            }
        }

        if (selectedCampaign) {
            fetchCampaign();
            fetchCampaignIntakes();
        }
    }, [selectedCampaign]);

    const exportToCsv = async () => {
        if(currentCampaign?.id){
            await downloadCampaignIntakes(currentCampaign.id)
        }
    }

    const columns = [
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <AdminActionMenu onViewIntakeClicked={handleViewMoreClicked}  law_firm_id={currentCampaign?.law_firm_id ?? 0} campaign_id={currentCampaign?.id ?? 0} intake={params.row} />
            ),
        },
        { field: 'qc_status', headerName: 'QC Status', width: 150 },
        {
            field: 'date',
            headerName: 'Date',
            width: 150,
            valueFormatter: (value: string) => value ? moment(value).format('MM/DD/YYYY') : ''
        },
        { field: 'injured_party_name', headerName: 'Injured Party Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone_number', headerName: 'Phone Number', width: 150 },
        { field: 'dob', headerName: 'DOB', width: 150 },
        { field: 'vendor_id', headerName: 'Vendor ID', width: 150 },
        {
            field: 'submitted_to_law_firm_date',
            headerName: 'Submitted to Law Firm Date',
            width: 200,
            valueFormatter: (value: string) => value ? moment(value).format('MM/DD/YYYY') : ''
        },
    ];

    const rows = gridData.map((row, index) => ({ id: index, ...row }));

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box width={250}>
                    <FormControl fullWidth>
                        <InputLabel id="law-firm-label">Law Firm</InputLabel>
                        <Select
                            labelId="law-firm-label"
                            id="law-firm-select"
                            value={selectedLawFirm}
                            label="Law Firm"
                            onChange={handleLawFirmChange}
                        >
                            {lawFirms.map((lawFirm) => (
                                <MenuItem key={lawFirm.id} value={lawFirm.id}>
                                    {lawFirm.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box ml={2} width={250}>
                    <FormControl fullWidth>
                        <InputLabel id="law-firm-campaigns-label">Campaigns</InputLabel>
                        <Select
                            labelId="law-firm-campaigns-label"
                            id="law-firm-campaigns-select"
                            value={selectedCampaign}
                            label="Campaign"
                            onChange={handleCampaignChange}
                        >
                            {campaigns.map((campaign) => (
                                <MenuItem key={campaign.id} value={campaign.id}>
                                    {campaign.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {currentCampaign && (
                    <Box ml={2}>
                        <Button variant="contained" color="primary" onClick={exportToCsv} >
                            Export Data
                        </Button>
                    </Box>
                )}
            </Box>

            <Box my={2}>
                {selectedCampaign && (
                    <>
                        {currentCampaign && (
                            <Box>
                                <Box>
                                    <Paper style={{ height: 600, width: '100%' }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                        />
                                    </Paper>
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </Box>

            {(viewMoreIntake && viewMoreDlgOpen) && <IntakeDetailDialog intake={viewMoreIntake} onClose={handleViewMoreDlgClosed} />}
        </Box>
    )
}

export default QCIntakesByCampaign