import React, { useEffect, useState } from 'react'
import { IUser } from "../../../types/IUser";
import { getLawFirmUsers } from "../../../services/lawFirmService";
import { Box } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

interface Props {
  law_firm_id: number
}

const LawFirmUsersTable: React.FC<Props> = (props) => {
  const { law_firm_id } = props

  const [lawFirmUsers, setLawFirmUsers] = useState<IUser[]>([])

  useEffect(() => {
    const fetchLawFirmUsers = async () => {
      const users = await getLawFirmUsers(law_firm_id)
      console.log('users...', users)
      setLawFirmUsers(users ?? [])

    }
    fetchLawFirmUsers();
  }, [law_firm_id]);

    return (
        <Box mb={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {lawFirmUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.first_name}</TableCell>
                      <TableCell>{user.last_name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
              ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
    )
}

export default LawFirmUsersTable