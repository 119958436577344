import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import { getReturnOptions } from "../../../services/returnsService";
import { IReturnOption } from "../ReturnButton";

interface Props {
  onCancel: () => void
  onSave: (option: string) => void
}

const TurnDownDialog: React.FC<Props> = (props) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [returnOptions, setReturnOptions] = useState<IReturnOption []>([])

  const { onCancel, onSave } = props

  useEffect(() => {
    getReturnOptions().then(reasons => {
      setReturnOptions(reasons)
    })

  }, []);

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation(); // Stop event propagation
    setSelectedOption(event.target.value);
  };

    return (
        <Dialog open={true} maxWidth={'xs'} fullWidth={true}>
          <DialogTitle>Return Intake</DialogTitle>
          <DialogContent>
            <Select
                error={!selectedOption}
                value={selectedOption}
                onChange={handleOptionChange}
                displayEmpty
                fullWidth
            >
              {returnOptions.map((ro) => (
                  <MenuItem value={ro.id}>{ro.reason}</MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={() => onSave(selectedOption)} color="primary">Return</Button>
          </DialogActions>
        </Dialog>
    )
}

export default TurnDownDialog