import { get, post } from "./api";


export interface IIntake {
    qc_status: string | undefined | null
    date: string | undefined | null
    vendor_id: string | undefined | null
    submitted_to_law_firm_date: string | undefined | null
    injured_party_name: string | undefined | null
    address: string | undefined | null
    email: string | undefined | null
    phone_number: string | undefined | null
    dob: string | undefined | null
}

export interface ICampaignQCSummary {
    counts: any []
    intakes: IIntake []
    sent_to_law_firm: number | undefined | null
    accepted_by_law_firm: number | undefined | null
}


export const getCampaign = async (id: number): Promise<IDashboardCampaign | undefined> => {
    try {
        const response = await fetch(`/api/campaigns/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data
    }catch (e){
        console.error(e)
        return undefined
    }
}

export const getCampaignIntakes = async (id: number): Promise<ICampaignQCSummary | undefined> => {
    const response = await fetch(`/api/campaigns/${id}/intakes`, {
        method: 'GET',
    });

    if (response.ok) {
        return await response.json()
    }

    return undefined
}


export const downloadCampaignIntakes = async (id: number): Promise<void> => {
    const response = await fetch(`/api/campaigns/${id}/intakes/export`, {
        method: 'GET',
    });

    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `campaign_intakes_${id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }else {
        console.error('Failed to fetch CSV')
    }
}

export const getCampaignLawFirmIntakes = async (id: number): Promise<ICampaignQCSummary | undefined> => {
    const response = await fetch(`/api/campaigns/${id}/law-firm-intakes`, {
        method: 'GET',
    });

    if (response.ok) {
        return await response.json()
    }

    return undefined
}

export const downloadCampaignLawFirmIntakes = async (id: number): Promise<void> => {
    const response = await fetch(`/api/campaigns/${id}/law-firm-intakes/export`, {
        method: 'GET',
    });

    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `campaign_intakes_${id}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }else {
        console.error('Failed to fetch CSV')
    }
}

interface ITurnDownRequest {
    phone_number: string
    campaign_id: number
    return_reason_id: number
    injured_party_name: string
}

interface ITurnDown {
    id: number
    campaign_id: number
    reason_id: number
    note?: string | undefined
}

export const postTurnDown = async (turnDown: ITurnDownRequest) => {
    await post<any>(`/intakes/return`, { body: turnDown })
    return true
}

export const getTurnDown = async (campaignId: number, phoneNumber: string) : Promise<ITurnDown | undefined> => {
    const result = await get<ITurnDown>(`/intakes/returns/campaigns/${campaignId}?phoneNumber=${phoneNumber}`)
    return result
}